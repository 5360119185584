import React from "react"

import Layout from "../components/layout"
import Products from "../components/products/all"
import SEO from "../components/seo"
import WelcomeMsg from "../components/welcome_msg/welcome_msg"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Strona domowa"
      description="Nazywam się Gosia Zboina (do niedawna Zuterek), jestem architektem i grafikiem. Na tej stronie możesz zapoznać się z moją twórczością"
    />
    {/* <WelcomeMsg /> */}
    <Products />
  </Layout>
)

export default IndexPage
